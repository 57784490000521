










// --- Vue & Template imports ---
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class DefaultDrawerHeader extends Vue {}
