






















// --- Vue & Template imports ---
import { Component, Vue } from 'vue-property-decorator';
import DefaultDrawerHeader from './widgets/DrawerHeader.vue';
import DefaultList from './List.vue';

// Utilities
import { Get, Sync } from 'vuex-pathify';

// --- Models ---
import { DrawerItem } from '@/models/App.model';
import AppService from '@/services/app';
import AuthService from '@/services/auth';

@Component({
  components: {
    DefaultDrawerHeader,
    DefaultList,
  },
})
export default class DefaultDrawer extends Vue {

  drawerDelay = false;
  items: DrawerItem[] = [
    {
      icon: this.$store.getters['app/icons'].estate,
      title: 'Estates',
      to: '/estates',
    },
    {
      icon: this.$store.getters['app/icons'].users,
      title: 'Users',
      to: '/users',
    },
    {
      icon: this.$store.getters['app/icons'].guard,
      title: 'Guards',
      to: '/guards',
    },
    {
      icon: this.$store.getters['app/icons'].scanner,
      title: 'Scan Hardware',
      to: '/scanHardware',
    },

  ];

  @Sync('app/drawer') drawer!: boolean;
  @Sync('app/mini') mini!: boolean;


}

// https://stackoverflow.com/questions/60354556/sasserror-undefined-variable-main-background-color
// this was breaking something, stopping the site from loading \/\/
// :not(.v-list-group__header).v-list-item--active
//       background-color: $accent !important

